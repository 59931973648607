import React, { FC, ReactNode, useMemo } from 'react';
import { Button, Input, Menu, Select } from 'antd';
import { connect } from 'react-redux';
import { CountBadge } from 'common/components/CountBadge';
import { ESearchParams } from 'common/const/searchParams.enum';
import { EInventoryListMenuTab } from 'common/const/inventory.enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { useInventoryContext } from 'common/hooks/useInventoryContext';
import {
  CHARACTER_LIMIT_MIN,
  DEFAULT_EMPTY_VALUE,
  DEFAULT_PAGINATION_PAGE,
  LIST_LIMIT_0,
  SELECT_LIST_HEIGHT_320,
} from 'common/config';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { getInventoryListFilterOptions } from 'entities/Inventory/Inventory.helper';
import { IInventoryActionListPayload } from 'entities/Inventory/Inventory.models';
import { WriteOffIncomingInventoryActionList } from 'entities/Inventory/components/WriteOffIncomingInventoryActionList';
import { IUserInventoryStatistics } from 'entities/User/User.models';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  statistics: IUserInventoryStatistics;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    statistics,
    // state
    subdivisionList,
    userList,
    // dispatch
    getUserList,
    getInventoryActionList,
  } = props;

  const { getSearchParam, setSearchParam } = useSearchParamsHook();
  const { setPage } = useInventoryContext();

  const { writeOffsCount } = statistics;
  const tab = getSearchParam(ESearchParams.Tab);
  const subdivisionOptions = getInventoryListFilterOptions.subdivision(subdivisionList);
  const userOptions = getInventoryListFilterOptions.user(userList);
  const menu = useMemo(() => {
    return (
      <Menu
        items={[
          {
            key: EInventoryListMenuTab.Incoming,
            label: (
              <>
                Входящие запросы
                <CountBadge count={writeOffsCount} />
              </>
            ),
          },
        ]}
        selectedKeys={[tab === null ? EInventoryListMenuTab.Incoming : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab, statistics]);

  const renderFilters = (payload: IInventoryActionListPayload, setPayload: (value: IInventoryActionListPayload) => void) => {
    return (
      <div className="inventory-list__filters mb-12">
        <Select
          rootClassName="redesign"
          className="inventory-list__filters-select"
          options={subdivisionOptions}
          value={payload?.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
          onChange={async (value: number) => {
            const newPayload = {
              ...payload,
              subdivisionIds: value !== DEFAULT_EMPTY_VALUE ? [value] : undefined,
              userId: undefined,
            };

            setPage(DEFAULT_PAGINATION_PAGE);
            setPayload(newPayload);

            await getUserList({ subdivisionId: newPayload.subdivisionIds?.[0], limit: LIST_LIMIT_0 });
            await getInventoryActionList(newPayload);
          }}
          placeholder={EPlaceholder.SelectCompany}
          listHeight={SELECT_LIST_HEIGHT_320}
          virtual={false}
          suffixIcon={<ArrowDownShortIcon />}
        />

        <Select
          rootClassName="redesign"
          className="inventory-list__filters-select"
          options={userOptions}
          value={payload?.userId !== undefined ? payload.userId : DEFAULT_EMPTY_VALUE}
          onChange={(value) => {
            const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

            setPage(DEFAULT_PAGINATION_PAGE);
            setPayload(newPayload);
            getInventoryActionList(newPayload);
          }}
          placeholder={EPlaceholder.SelectUser}
          listHeight={SELECT_LIST_HEIGHT_320}
          virtual={false}
          suffixIcon={<ArrowDownShortIcon />}
        />

        <Input
          style={{ flex: 1 }}
          placeholder={EPlaceholder.SearchByNameOrArticle}
          value={payload?.search}
          onChange={(e) => {
            const value = e.target.value;

            setPayload({ ...payload, search: value.trim().length >= CHARACTER_LIMIT_MIN ? value : undefined });
          }}
        />

        <Button
          className="button-circle primary"
          icon={<SearchIcon />}
          onClick={() => {
            setPage(DEFAULT_PAGINATION_PAGE);
            getInventoryActionList(payload);
          }}
        />
      </div>
    );
  };

  return (
    <WriteOffIncomingInventoryActionList header={header} navigation={navigation} menu={menu} renderFilters={renderFilters} />
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.subdivisionListState.data,
  userList: state.userListState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserList: dispatch.userListState.getUserList,
  getInventoryActionList: dispatch.inventoryActionListState.getInventoryActionList,
});

export const WriteOffInventoryActionList = connect(mapState, mapDispatch)(Component);
