/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { DEFAULT_GOODS_COUNT } from 'common/config';
import { RootDispatch, RootState } from 'app/store';

export interface IGoodsContext {
  count: number;
  setCount: (value: number) => void;
  openGoodsCard: boolean;
  setOpenGoodsCard: (value: boolean) => void;
  onOpenGoodsCard: (id: number, goodInBasket: boolean, catalogId?: number) => void;
}

export const GoodsContext = createContext<IGoodsContext>({
  count: DEFAULT_GOODS_COUNT,
  setCount: () => {},
  openGoodsCard: false,
  setOpenGoodsCard: () => {},
  onOpenGoodsCard: () => {},
});

export const useGoodsContext = () => useContext(GoodsContext);

export interface IComponentProps {
  children: React.ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const Component: FC<AllType> = ({ children, currentBasket, getGoods }) => {
  const [count, setCount] = useState<number>(DEFAULT_GOODS_COUNT);
  const [openGoodsCard, setOpenGoodsCard] = useState<boolean>(false);

  const onOpenGoodsCard = (id: number, goodInBasket: boolean, catalogId?: number) => {
    if (goodInBasket && currentBasket) {
      const goodsFromBasket = currentBasket.goods.find((goods) => goods.goodId === id);

      if (goodsFromBasket) {
        setCount(goodsFromBasket.count);
      }
    } else {
      setCount(DEFAULT_GOODS_COUNT);
    }

    getGoods({ id, catalogId, onSuccess: () => setOpenGoodsCard(true) });
  };

  return (
    <GoodsContext.Provider
      value={{
        count,
        setCount,
        openGoodsCard,
        setOpenGoodsCard,
        onOpenGoodsCard,
      }}
    >
      {children}
    </GoodsContext.Provider>
  );
};

const mapState = (state: RootState) => ({
  currentBasket: state.basketState.currentBasket,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getGoods: dispatch.goodsState.getGoods,
});

export const GoodsProvider = connect(mapState, mapDispatch)(Component);
