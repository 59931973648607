import React, { FC } from 'react';
import { Button, Checkbox, Input, Modal, Spin, Tree } from 'antd';
import { DataNode } from 'antd/es/tree';
import { useTreeSearch } from 'common/hooks/useTreeSearch';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/search.svg';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  defaultTreeData: DataNode[];
  search: string;
  checkedAll: boolean;
  indeterminate: boolean;
  checkedKeys: number[];
  expandedKeys: number[];
  changeSearch: (value: string) => void;
  changeCheckedKeys: (value: number[]) => void;
  changeExpandedKeys: (value: number[]) => void;
  onCheckAll: () => void;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ContractCategorySettingsModal: FC<IComponentProps> = (props) => {
  const {
    open,
    loading,
    defaultTreeData,
    search,
    checkedAll,
    indeterminate,
    checkedKeys,
    expandedKeys,
    changeSearch,
    changeCheckedKeys,
    changeExpandedKeys,
    onCheckAll,
    onCancel,
    onConfirm,
  } = props;

  const { treeData } = useTreeSearch(defaultTreeData, search);

  return (
    <Modal
      className="modal contract-category-settings-modal"
      open={open}
      footer={false}
      closeIcon={false}
      onCancel={onCancel}
      width={400}
    >
      <Spin spinning={loading}>
        <div className="modal-content">
          <div className="modal-footer">
            <Button className="btn btn-default" onClick={onCancel}>
              Отмена
            </Button>

            <Button className="btn btn-primary" onClick={onConfirm}>
              Подтвердить
            </Button>
          </div>

          <Input placeholder="Поиск" value={search} onChange={(e) => changeSearch(e.target.value)} suffix={<SearchIcon />} />

          <Checkbox indeterminate={indeterminate} checked={checkedAll} onChange={onCheckAll}>
            Выделить все
          </Checkbox>

          <Tree
            className="contract-category-settings-modal__tree"
            height={300}
            checkable
            checkStrictly
            treeData={treeData}
            switcherIcon={<ChevronIcon />}
            checkedKeys={checkedKeys}
            // @ts-ignore
            onCheck={(value) => changeCheckedKeys(value.checked)}
            onExpand={(value) => changeExpandedKeys(value as number[])}
            expandedKeys={expandedKeys}
          />
        </div>
      </Spin>
    </Modal>
  );
};
