import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Spin, Table } from 'antd';
import { connect } from 'react-redux';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, LIST_LIMIT_20 } from 'common/config';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { EGroupedSupplyListKey, ESupplyListMenuTab, ESupplyStatus } from 'common/const/supply.enum';
import { EDateFormat } from 'common/const/date.enum';
import { groupList } from 'common/helpers/common.helper';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as ArrowUpShortIcon } from 'app/assets/images/redesign/arrow-up-short.svg';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { getNeedFormattedDate } from 'entities/Need/Need.helper';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { IGroupedSupplyListPayload } from 'entities/Supply/Supply.models';
import { renderGroupedSupplyListRecords, renderSupplyListRecords } from 'entities/Supply/Supply.helper';
import { SupplyListEmpty } from 'entities/Supply/components/SupplyListEmpty';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  menu: ReactNode;
  renderFilters: (payload: IGroupedSupplyListPayload, setPayload: (value: IGroupedSupplyListPayload) => void) => ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    menu,
    renderFilters,
    // state
    supplyListLoading,
    groupedSupplyList,
    groupedSupplyListCount,
    groupedSupplyListLoading,
    // dispatch
    getSupplySubdivisionListForManager,
    getSupplyUserList,
    setGroupedSupplyList,
    getGroupedSupplyList,
    getGroupedSupplyListPart,
    getSupplyById,
    getNeedById,
  } = props;

  const [payload, setPayload] = useState<IGroupedSupplyListPayload>({
    statuses: [ESupplyStatus.Delivered, ESupplyStatus.Cancelled],
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
    key: EGroupedSupplyListKey.ByUpdatedAt,
  });
  const { page, setPage, setOpenSupplyCard, fetchLoading, setFetchLoading } = useSupplyContext();

  const groupedList = groupList.byAddressLegalUser(groupedSupplyList);

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setGroupedSupplyList([]);
      await getSupplySubdivisionListForManager({ statuses: payload.statuses });
      await getSupplyUserList({ statuses: payload.statuses });
      await getGroupedSupplyList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!groupedSupplyListLoading && groupedSupplyList.length < groupedSupplyListCount}
        scrollToTopTrigger={[payload]}
        onLoad={() => {
          setPage(page + 1);
          getGroupedSupplyListPart({ ...payload, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}
        {navigation}
        {menu}
        {renderFilters(payload, setPayload)}

        <Spin
          wrapperClassName="need-list__spin"
          spinning={groupedSupplyListLoading || supplyListLoading || fetchLoading}
          indicator={<SpinIndicator />}
        >
          {groupedList.map((group, index) => {
            return (
              <div key={index} className="need-list__group">
                <span className="need-list__group-date">{getNeedFormattedDate(EDateFormat.FullDate, group.updatedAt)}</span>

                <Table
                  className="need-list__table need-list__table-expandable"
                  rowClassName="need-list__table-expandable-row"
                  columns={renderGroupedSupplyListRecords()}
                  dataSource={group.dataSource.map((item, index) => ({ ...item, key: index }))}
                  expandable={{
                    expandIconColumnIndex: 7,
                    columnWidth: 40,
                    expandIcon: ({ expanded, onExpand, record }) => {
                      return (
                        <Button
                          className="button-icon"
                          icon={
                            expanded ? (
                              <ArrowUpShortIcon className="icon-arrow-up-short-dark-grey" />
                            ) : (
                              <ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />
                            )
                          }
                          onClick={(e) => onExpand(record, e)}
                        />
                      );
                    },
                    expandedRowRender: ({ dataSource }) => {
                      return (
                        <Table
                          className="table-hover need-list__table"
                          columns={renderSupplyListRecords(ESupplyListMenuTab.Completed)}
                          dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
                          pagination={false}
                          showHeader={false}
                          onRow={({ id, supplyNeedId }) => ({
                            onClick: async () => {
                              await getSupplyById({ id, onSuccess: () => setOpenSupplyCard(true) });
                              await getNeedById({ id: supplyNeedId });
                            },
                          })}
                        />
                      );
                    },
                  }}
                  pagination={false}
                  showHeader={false}
                />
              </div>
            );
          })}

          <SupplyListEmpty
            open={!(groupedSupplyListLoading || supplyListLoading || fetchLoading) && !groupedSupplyList.length}
            forSeller
            isCompleted
          />
        </Spin>
      </InfiniteScrollContainer>

      <SupplyCard />

      <NeedCard nested />

      <GoodsCard />
    </>
  );
};

const mapState = (state: RootState) => ({
  supplyListLoading: state.supplyListState.loading,
  groupedSupplyList: state.groupedSupplyListState.data,
  groupedSupplyListCount: state.groupedSupplyListState.count,
  groupedSupplyListLoading: state.groupedSupplyListState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSupplySubdivisionListForManager: dispatch.supplyListState.getSupplySubdivisionListForManager,
  getSupplyUserList: dispatch.supplyListState.getSupplyUserList,
  setGroupedSupplyList: dispatch.groupedSupplyListState.setGroupedSupplyList,
  getGroupedSupplyList: dispatch.groupedSupplyListState.getGroupedSupplyList,
  getGroupedSupplyListPart: dispatch.groupedSupplyListState.getGroupedSupplyListPart,
  getSupplyById: dispatch.supplyState.getSupplyById,
  getNeedById: dispatch.needState.getNeedById,
});

export const CompletedSupplyListForManager = connect(mapState, mapDispatch)(Component);
