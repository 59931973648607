import { RematchDispatch, RematchRootState } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { destroyMessage, showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  ICategoryCreatePayload,
  ICategoryDeletePayload,
  ICategoryDetailed,
  ICategoryListParams,
  ICategoryUpdatePayload,
} from 'entities/Categories/Categories.models';
import { categoriesTransport } from 'entities/Categories/Categories.transport';

export const categoryListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getCategoryList(params: ICategoryListParams) {
    dispatch.categoryListState.setCategoryListLoading(true);

    try {
      const response = await categoriesTransport.getCategoryList(params);
      dispatch.categoryListState.setCategoryList(response.data);
      dispatch.categoryListState.setCategoryListCount(response.count);
      return response.data;
    } catch (error) {
      axiosErrorHandler(error);
      return null;
    } finally {
      dispatch.categoryListState.setCategoryListLoading(false);
    }
  },
  async searchCategoriesByName(params: ICategoryListParams) {
    try {
      const response = await categoriesTransport.getCategoryList(params);
      return response.data;
    } catch (error) {
      axiosErrorHandler(error);
      return [];
    }
  },
});

export const categoryStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getCategoryById(id: number) {
    dispatch.categoryState.setCategoryLoading(true);

    try {
      const response = await categoriesTransport.getCategoryById(id);
      dispatch.categoryState.setCategory(response);
      return response;
    } catch (error) {
      axiosErrorHandler(error);
      return null;
    } finally {
      dispatch.categoryState.setCategoryLoading(false);
    }
  },
  async createCategory(payload: ICategoryCreatePayload) {
    dispatch.categoryState.setCategoryLoading(true);

    showLoadingMessage(0);

    try {
      const response = await categoriesTransport.createCategory(payload);
      dispatch.categoryListState.addCategory(response);
      dispatch.categoryState.onSuccess(payload);
      showSuccessMessage();
    } catch (error) {
      destroyMessage();
      axiosErrorHandler(error);
    } finally {
      dispatch.categoryState.setCategoryLoading(false);
    }
  },
  async updateCategory(payload: ICategoryUpdatePayload) {
    dispatch.categoryState.setCategoryLoading(true);

    showLoadingMessage(0);

    try {
      const response = await categoriesTransport.updateCategory(payload);
      dispatch.categoryListState.updateCategory(response);
      dispatch.categoryState.setCategory(response);
      dispatch.categoryState.onSuccess(payload);
      showSuccessMessage();
    } catch (error) {
      destroyMessage();
      axiosErrorHandler(error);
    } finally {
      dispatch.categoryState.setCategoryLoading(false);
    }
  },
  async deleteCategory(payload: ICategoryDeletePayload, models: RematchRootState<IRootModel, Record<string, never>>) {
    dispatch.categoryState.setCategoryLoading(true);

    const selectedCategory = models.categoryState.data;

    showLoadingMessage(0);

    try {
      const response = await categoriesTransport.deleteCategory(payload);

      if (response.id === selectedCategory?.id) {
        dispatch.categoryState.setCategory(null);
      }

      dispatch.categoryListState.deleteCategory(response.id);
      dispatch.categoryState.onSuccess(payload);
      showSuccessMessage();
    } catch (error) {
      destroyMessage();
      axiosErrorHandler(error);
    } finally {
      dispatch.categoryState.setCategoryLoading(false);
    }
  },
  selectCategory(id: number, models: RematchRootState<IRootModel, Record<string, never>>) {
    const categoryList = models.categoryListState.data;
    const category = categoryList.find((categoryItem) => categoryItem.id === id);

    if (category) {
      dispatch.categoryState.setCategory(category as ICategoryDetailed);
    }
  },
});
