import React from 'react';
import { Button, type MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { EDateFormat } from 'common/const/date.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { EUserFilterOption, EUserFilterOptionLabel, EUserStatus } from 'common/const/user.enum';
import { ESettingsMenuTab } from 'common/const/settings.enum';
import { getSettingsPath } from 'common/helpers/router.helper';
import { ReactComponent as SuccessIcon } from 'app/assets/images/success.svg';
import { ReactComponent as ErrorIcon } from 'app/assets/images/error.svg';
import { IUser } from 'entities/User/User.models';
import { ICatalog } from 'entities/Catalog/Catalog.models';
import { getUserName, getUserRole } from 'entities/User/User.helper';
import { IInvitationCreatePayload } from 'entities/Invitation/Invitation.models';

export const getSettingsNavItems = (roles?: string[], isSeller?: boolean) => {
  const { isSuperAdmin, isAccountAdmin } = getUserRole(roles);
  const navItems: MenuProps['items'] = [];

  if (isSuperAdmin) {
    navItems.push({
      key: ESettingsMenuTab.AccountList,
      label: <Link to={getSettingsPath(ESettingsMenuTab.AccountList)}>Список аккаунтов</Link>,
    });
  }

  if (isAccountAdmin) {
    navItems.push(
      {
        key: ESettingsMenuTab.Account,
        label: <Link to={getSettingsPath(ESettingsMenuTab.Account)}>Настройки аккаунта</Link>,
      },
      {
        key: ESettingsMenuTab.Users,
        label: <Link to={getSettingsPath(ESettingsMenuTab.Users)}>Пользователи</Link>,
      },
    );

    if (isSeller) {
      navItems.push(
        {
          key: ESettingsMenuTab.Stocks,
          label: <Link to={getSettingsPath(ESettingsMenuTab.Stocks)}>Склады отгрузки</Link>,
        },
        {
          key: ESettingsMenuTab.Discounts,
          label: <Link to={getSettingsPath(ESettingsMenuTab.Discounts)}>Скидки</Link>,
        },
        {
          key: ESettingsMenuTab.Integration,
          label: <Link to={getSettingsPath(ESettingsMenuTab.Integration)}>Интеграции</Link>,
        },
      );
    } else {
      navItems.push(
        {
          key: ESettingsMenuTab.Subdivisions,
          label: <Link to={getSettingsPath(ESettingsMenuTab.Subdivisions)}>Подразделения</Link>,
        },
        {
          key: ESettingsMenuTab.LegalEntities,
          label: <Link to={getSettingsPath(ESettingsMenuTab.LegalEntities)}>Юр.лица</Link>,
        },
      );
    }
  }

  return navItems.map((item) => ({ ...item, className: 'workplace-nav__link' }));
};

const usersListItemCellClassName = 'users__list_item-cell';

export const renderUsersSettingsRecords = (
  setSearchParams: (key: ESearchParams, value: string | number) => void,
  onInviteUser: (payload: IInvitationCreatePayload) => void,
) => {
  return [
    {
      onCell: () => ({ className: `${usersListItemCellClassName} user-status` }),
      key: 'status',
      render: (record) => (
        <div className="users__list_item-status">
          {record?.status === EUserStatus.Active ? <SuccessIcon className="success" /> : <ErrorIcon />}
        </div>
      ),
    },
    {
      onCell: () => ({ className: usersListItemCellClassName }),
      key: 'firstName',
      render: (record) => (
        <Button type="link" onClick={() => setSearchParams(ESearchParams.UserId, record.id)}>
          {getUserName(record.firstName, record.lastName)}
        </Button>
      ),
      ellipsis: true,
    },
    {
      onCell: () => ({ className: `${usersListItemCellClassName} role` }),
      key: 'role',
      render: (record) => {
        const { isAccountAdmin } = getUserRole(record.roles);

        return isAccountAdmin ? <span>Администратор</span> : null;
      },
    },
    {
      onCell: () => ({ className: `${usersListItemCellClassName} email` }),
      key: 'email',
      render: (record) => <div title={record.email}>{record.email}</div>,
      ellipsis: true,
    },
    {
      key: 'invite',
      render: (_, record) => {
        if (record.status === EUserStatus.Active) {
          return null;
        } else {
          return (
            <ThreeDotMenu
              items={[
                {
                  key: 'invite',
                  label: (
                    <Button
                      onClick={() =>
                        onInviteUser({
                          email: record.email,
                          firstName: record.firstName,
                          lastName: record.lastName,
                          subdivisionId: record.subdivisionId,
                        })
                      }
                    >
                      Пригласить повторно
                    </Button>
                  ),
                },
              ]}
            />
          );
        }
      },
      width: 64,
    },
  ] as ColumnsType<IUser>;
};

export const getUserSettingsFilterOptions = () => {
  return Object.values(EUserFilterOption).map((item) => ({ label: EUserFilterOptionLabel[item], value: item }));
};

export const toUserSettingsSearchParams = (currentUser: IUser | null, searchValue: string, filterList: EUserFilterOption[]) => {
  return {
    accountId: currentUser?.accountId,
    search: searchValue,
    withoutSubdivision: filterList.includes(EUserFilterOption.WithoutSubdivision) ? true : undefined,
    status: filterList.includes(EUserFilterOption.NotActive) ? EUserStatus.NotActive : undefined,
    limit: 0,
  };
};

export const renderCatalogListSettingsRecords = (
  handleUpdateCatalogClick: (record: ICatalog) => void,
  handleDeleteCatalogClick: (catalogId: number) => void,
) => {
  return [
    {
      title: 'Название каталога',
      dataIndex: 'name',
    },
    {
      title: 'Создан',
      dataIndex: 'createdAt',
      render: (text) => dayjs(text).locale('ru').format(EDateFormat.FullDate),
      width: 200,
    },
    {
      title: 'Изменен',
      dataIndex: 'updatedAt',
      render: (text) => dayjs(text).locale('ru').format(EDateFormat.FullDate),
      width: 200,
    },
    {
      title: 'Автор',
    },
    {
      title: '',
      render: (_, record) => (
        <ThreeDotMenu
          items={[
            {
              key: 'edit',
              label: (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUpdateCatalogClick(record);
                  }}
                >
                  Редактировать
                </Button>
              ),
              className: 'edit',
            },
            {
              key: 'delete',
              label: (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteCatalogClick(record.id);
                  }}
                >
                  Удалить
                </Button>
              ),
              className: 'delete',
            },
          ]}
        />
      ),
      width: 64,
    },
  ] as ColumnsType<ICatalog>;
};
