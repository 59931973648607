import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IGoodsListState, IGoodsState } from 'entities/Goods/Goods.models';
import { goodsListStateReducers, goodsStateReducers } from 'entities/Goods/Goods.reducers';
import { goodsListStateEffects, goodsStateEffects } from 'entities/Goods/Goods.effects';

export const goodsListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as IGoodsListState,
  reducers: goodsListStateReducers,
  effects: goodsListStateEffects,
});

export const goodsState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IGoodsState,
  reducers: goodsStateReducers,
  effects: goodsStateEffects,
});
