import { ICategory, ICategoryDetailed, ICategoryListState, ICategoryState } from 'entities/Categories/Categories.models';

export const categoryListStateReducers = {
  setCategoryList: (state: ICategoryListState, payload: ICategory[]) => ({ ...state, data: payload }),
  setCategoryListCount: (state: ICategoryListState, payload: number) => ({ ...state, count: payload }),
  setCategoryListLoading: (state: ICategoryListState, payload: boolean) => ({ ...state, loading: payload }),
  addCategory: (state: ICategoryListState, payload: ICategoryDetailed) => ({
    ...state,
    data: [...state.data, payload],
  }),
  updateCategory: (state: ICategoryListState, payload: ICategoryDetailed) => ({
    ...state,
    data: state.data.map((category) => (category.id === payload.id ? payload : category)),
  }),
  deleteCategory: (state: ICategoryListState, payload: number) => ({
    ...state,
    data: state.data.filter(({ id }) => id !== payload),
  }),
};

export const categoryStateReducers = {
  setCategory: (state: ICategoryState, payload: ICategoryDetailed | null) => ({ ...state, data: payload }),
  setCategoryLoading: (state: ICategoryState, payload: boolean) => ({ ...state, loading: payload }),
};
