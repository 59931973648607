import { IWorkspace } from 'entities/Workspace/Workspace.models';
import {
  IUser,
  IUserInventoryStatistics,
  IUserInventoryStatisticsState,
  IUserListState,
  IUserState,
  IUserStatistics,
  IUserStatisticsState,
} from 'entities/User/User.models';

export const userListStateReducers = {
  setUserList: (state: IUserListState, payload: IUser[]) => ({ ...state, data: payload }),
  setUserListLoading: (state: IUserListState, payload: boolean) => ({ ...state, loading: payload }),
  addUser: (state: IUserListState, payload: IUser) => ({ ...state, data: [...state.data, payload] }),
  updateUser: (state: IUserListState, payload: IUser) => ({
    ...state,
    data: state.data.map((user) => (user.id === payload.id ? payload : user)),
  }),
  deleteUser: (state: IUserListState, payload: number) => ({
    ...state,
    data: state.data.filter((user) => user.id !== payload),
  }),
  updateWorkspace: (state: IUserListState, payload: IWorkspace) => {
    return {
      ...state,
      data: state.data.map((user) => {
        if (user.id === payload.user?.id) {
          return { ...user, workspaceId: payload.id, workspace: { ...user.workspace, name: payload.name } };
        }

        return user.workspaceId === payload.id ? { ...user, workspaceId: undefined, workspace: undefined } : user;
      }),
    };
  },
  deleteWorkspace: (state: IUserListState, payload: IWorkspace) => {
    return {
      ...state,
      data: state.data.map((user) => {
        if (user.id === payload.user?.id) {
          return { ...user, workspaceId: undefined, workspace: undefined };
        }

        return user;
      }),
    };
  },
};

export const userStateReducers = {
  setUser: (state: IUserState, payload: IUser) => {
    return { ...state, data: payload, currentUser: payload.id === state.currentUser?.id ? payload : state.currentUser };
  },
  setCurrentUser: (state: IUserState, payload: IUser | null) => ({ ...state, currentUser: payload }),
  setUserLoading: (state: IUserState, payload: boolean) => ({ ...state, loading: payload }),
  setUserError: (state: IUserState, payload: string | null) => ({ ...state, error: payload }),
  setUserAutoSupplyApprove: (state: IUserState, payload: boolean) => ({
    ...state,
    currentUser: state.currentUser ? { ...state.currentUser, autoSupplyApprove: payload } : null,
  }),
  updateWorkspace: (state: IUserState, payload: IWorkspace) => {
    if (state.currentUser) {
      let newCurrentUser = {} as IUser;

      if (state.currentUser.id === payload.user?.id) {
        newCurrentUser = {
          ...state.currentUser,
          workspaceId: payload.id,
          workspace: { name: payload.name },
        };
      }

      if (state.currentUser.id !== payload.user?.id && state.currentUser.workspaceId === payload.id) {
        newCurrentUser = {
          ...state.currentUser,
          workspaceId: undefined,
          workspace: undefined,
        };
      }

      return { ...state, currentUser: newCurrentUser };
    }

    return state;
  },
  deleteWorkspace: (state: IUserState, payload: IWorkspace) => {
    return {
      ...state,
      currentUser: state.currentUser
        ? state.currentUser.id === payload.user?.id
          ? {
              ...state.currentUser,
              workspaceId: undefined,
              workspace: undefined,
            }
          : state.currentUser
        : null,
    };
  },
};

export const accountUserListStateReducers = {
  setAccountUserList: (state: IUserListState, payload: IUser[]) => ({ ...state, data: payload }),
  setAccountUserListLoading: (state: IUserListState, payload: boolean) => ({ ...state, loading: payload }),
};

export const statisticsStateReducers = {
  setStatistics: (state: IUserStatisticsState, payload: IUserStatistics) => ({ ...state, data: payload }),
  setStatisticsLoading: (state: IUserStatisticsState, payload: boolean) => ({ ...state, loading: payload }),
};

export const userInventoryStatisticsStateReducers = {
  setUserInventoryStatistics: (state: IUserInventoryStatisticsState, payload: IUserInventoryStatistics) => ({
    ...state,
    data: payload,
  }),
  setLoading: (state: IUserInventoryStatisticsState, payload: boolean) => ({ ...state, loading: payload }),
};
