import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { TabNavigation } from 'common/components/TabNavigation';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { ERoute } from 'common/const/route.enum';
import { EInventoryListNavigationTab } from 'common/const/inventory.enum';
import { InventoryProvider } from 'common/hooks/useInventoryContext';
import { GoodsProvider } from 'common/hooks/useGoodsContext';
import { DEFAULT_VALUE_0 } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { WriteOffInventoryActionList } from 'entities/Inventory/components/WriteOffInventoryActionList';
import { TransferInventoryActionList } from 'entities/Inventory/components/TransferInventoryActionList';
import { AllInventoryList } from 'entities/Inventory/components/AllInventoryList';
import { getUserRole } from 'entities/User/User.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export const Component: FC<AllType> = ({ currentUser, userInventoryStatistics, getUserInventoryStatistics }) => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const { tab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { incomingTransfersCount, outcomingTransfersCount, writeOffsCount } = userInventoryStatistics;
  const { isAccountAdmin } = getUserRole(currentUser?.roles);
  const isTransferInventoryActionList = tab === EInventoryListNavigationTab.Transfer;
  const isWriteOffInventoryActionList = tab === EInventoryListNavigationTab.WriteOff;
  const isAllInventoryActionList = tab === EInventoryListNavigationTab.All;
  const header = <div className="text-body color-dark-grey mb-20">Учёт инструмента</div>;

  const getTabNavigationItems = () => {
    const items = [];

    if (incomingTransfersCount > DEFAULT_VALUE_0 || outcomingTransfersCount > DEFAULT_VALUE_0) {
      items.push({
        label: isAccountAdmin ? 'Запросы о передаче' : 'Передача инструмента',
        path: `${ERoute.InventoryList}/${EInventoryListNavigationTab.Transfer}`,
      });
    }

    if (isAccountAdmin && writeOffsCount > DEFAULT_VALUE_0) {
      items.push({
        label: 'Запросы о списании',
        path: `${ERoute.InventoryList}/${EInventoryListNavigationTab.WriteOff}`,
      });
    }

    items.push({
      label: isAccountAdmin ? 'Все инструменты' : 'Мои инструменты',
      path: `${ERoute.InventoryList}/${EInventoryListNavigationTab.All}`,
    });

    return items;
  };

  const navigation = <TabNavigation items={getTabNavigationItems()} />;

  useEffect(() => {
    if (!fetchLoading && location.pathname === ERoute.InventoryList) {
      const { incomingTransfersCount, outcomingTransfersCount, writeOffsCount } = userInventoryStatistics;

      if (incomingTransfersCount > DEFAULT_VALUE_0 || outcomingTransfersCount > DEFAULT_VALUE_0) {
        navigate(`${ERoute.InventoryList}/${EInventoryListNavigationTab.Transfer}`, { replace: true });
      } else if (isAccountAdmin && writeOffsCount > DEFAULT_VALUE_0) {
        navigate(`${ERoute.InventoryList}/${EInventoryListNavigationTab.WriteOff}`, { replace: true });
      } else {
        navigate(`${ERoute.InventoryList}/${EInventoryListNavigationTab.All}`, { replace: true });
      }
    }
  }, [fetchLoading, userInventoryStatistics, location.pathname]);

  useEffect(() => {
    const fetch = async () => {
      await getUserInventoryStatistics();
      setFetchLoading(false);
    };

    fetch();
  }, []);

  if (fetchLoading) {
    return (
      <div className="redesign inventory-list">
        <Spin indicator={<SpinIndicator />} />
      </div>
    );
  }

  return (
    <InventoryProvider>
      <GoodsProvider>
        <div className="redesign inventory-list">
          {isTransferInventoryActionList && (
            <TransferInventoryActionList header={header} navigation={navigation} statistics={userInventoryStatistics} />
          )}
          {isWriteOffInventoryActionList && (
            <WriteOffInventoryActionList header={header} navigation={navigation} statistics={userInventoryStatistics} />
          )}
          {isAllInventoryActionList && (
            <AllInventoryList header={header} navigation={navigation} statistics={userInventoryStatistics} />
          )}
        </div>
      </GoodsProvider>
    </InventoryProvider>
  );
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
  userInventoryStatistics: state.userInventoryStatisticsState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserInventoryStatistics: dispatch.userInventoryStatisticsState.getUserInventoryStatistics,
});

export const InventoryList = connect(mapState, mapDispatch)(Component);
