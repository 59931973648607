import React, { FC, useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { useCatalogContext } from 'common/hooks/useCatalogContext';
import { mapToMenuWithDivider, stringToNumber } from 'common/helpers/common.helper';
import { ITEMS_FOR_VIEW_LIMIT_4 } from 'common/config';
import { ReactComponent as MenuIcon } from 'app/assets/images/redesign/menu.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ICatalog } from 'entities/Catalog/Catalog.models';
import { getCatalogMenuItems } from 'entities/Catalog/Catalog.helper';

interface IComponentProps {
  catalogId?: string;
  catalogList: ICatalog[];
  loading: boolean;
}

export const CatalogSelector: FC<IComponentProps> = ({ catalogId, catalogList, loading }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { onCatalogChange } = useCatalogContext();

  const selectedKeys = catalogId ? [catalogId] : [];
  const feedCatalogList = mapToMenuWithDivider(getCatalogMenuItems(catalogList.filter((catalog) => !!catalog.sellerId)));
  const systemCatalogList = mapToMenuWithDivider(getCatalogMenuItems(catalogList.filter((catalog) => !catalog.sellerId)));

  const handleSelect = (key: string) => {
    onCatalogChange(stringToNumber(key));
    setOpen(false);
  };

  return (
    <Dropdown
      overlayClassName="redesign"
      open={open}
      onOpenChange={setOpen}
      trigger={['click']}
      dropdownRender={() => {
        if (!feedCatalogList.length && !systemCatalogList.length) {
          return (
            <div className="custom-dropdown catalog-selector__dropdown-container">
              <span className="text-lists color-dark-grey">В списке нет вариантов.</span>
            </div>
          );
        }

        return (
          <div className="custom-dropdown catalog-selector__dropdown">
            <div
              className={`catalog-selector__dropdown-wrapper ${
                feedCatalogList.length > ITEMS_FOR_VIEW_LIMIT_4 || systemCatalogList.length > ITEMS_FOR_VIEW_LIMIT_4
                  ? 'custom-dropdown__scrollable scrollbar'
                  : ''
              }`}
            >
              <div className="catalog-selector__dropdown-container">
                <span className="text-tag-accent color-bright-green custom-dropdown__title">Каталоги поставщиков</span>

                <Menu items={feedCatalogList} onClick={({ key }) => handleSelect(key)} selectedKeys={selectedKeys} />
              </div>

              {!!systemCatalogList.length && (
                <div className="catalog-selector__dropdown-container">
                  <span className="text-tag-accent color-bright-green custom-dropdown__title">Отрасли</span>

                  <Menu items={systemCatalogList} onClick={({ key }) => handleSelect(key)} selectedKeys={selectedKeys} />
                </div>
              )}
            </div>
          </div>
        );
      }}
      disabled={loading}
    >
      <Button
        className={`button-s ${open ? 'success' : 'primary'}`}
        icon={open ? <CloseIcon className="icon-close" /> : <MenuIcon className="icon-menu" />}
      >
        Каталог
      </Button>
    </Dropdown>
  );
};
