import React from 'react';
import { Checkbox } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { NameLabel } from 'common/components/NameLabel';
import { SupplyStatus } from 'common/components/SupplyStatus';
import { Image } from 'common/components/Image';
import { ERoute } from 'common/const/route.enum';
import { ESupplyListMenuTab, ESupplyStatus } from 'common/const/supply.enum';
import { ERequestsMenuTab, ERequestsNavigationTab } from 'common/const/request.enum';
import { EColor } from 'common/const/common.enum';
import { countFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { IGroupedSupplyListItemDataSource } from 'common/models';
import { ReactComponent as AffiliateIcon } from 'app/assets/images/redesign/affiliate.svg';
import { ReactComponent as ContractIcon } from 'app/assets/images/redesign/contract.svg';
import {
  GroupedSupplyListStateDataItemType,
  IGroupedByDeliveryDateSupply,
  IGroupedSupplyListState,
  ISupply,
  ISupplyAddress,
  ISupplyLegal,
  ISupplyListState,
  ISupplySubdivision,
  ISupplyUser,
} from 'entities/Supply/Supply.models';
import { getUserName } from 'entities/User/User.helper';

dayjs.extend(isSameOrAfter);

export const getSupplyStatusColor = (status?: string) => {
  if (status === ESupplyStatus.Approved) {
    return EColor.Blue;
  } else if (status === ESupplyStatus.Cancelled) {
    return EColor.Red;
  } else if (status === ESupplyStatus.Created) {
    return EColor.Gray;
  } else {
    return EColor.Green;
  }
};

const sortGroupedSupplyListByDeliveryDate = (data: IGroupedByDeliveryDateSupply[]) => {
  return data.sort((a, b) => {
    if (a.deliveryDate === null) {
      return -1;
    }

    if (b.deliveryDate === null) {
      return 1;
    }

    return a.deliveryDate.localeCompare(b.deliveryDate);
  });
};

export const updateSupplyListState = {
  setSupplyList: (state: ISupplyListState, payload: ISupply[]) => ({ ...state, data: payload }),
  setSupplyListPart: (state: ISupplyListState, payload: ISupply[]) => ({ ...state, data: [...state.data, ...payload] }),
  setSupplyListCount: (state: ISupplyListState, payload: number) => ({ ...state, count: payload }),
  setSupplyListLoading: (state: ISupplyListState, payload: boolean) => ({ ...state, loading: payload }),
  setSupplyListUserList: (state: ISupplyListState, payload: ISupplyUser[]) => ({ ...state, userList: payload }),
  setSupplyListSubdivisionList: (state: ISupplyListState, payload: ISupplySubdivision[]) => ({
    ...state,
    subdivisionList: payload,
  }),
  setSupplyListLegalList: (state: ISupplyListState, payload: ISupplyLegal[]) => ({ ...state, legalList: payload }),
  setSupplyListAddressList: (state: ISupplyListState, payload: ISupplyAddress[]) => ({ ...state, addressList: payload }),
  updateSupplyList: (state: ISupplyListState, payload: ISupply[]) => {
    return {
      ...state,
      data: state.data.map((supply) => {
        const item = payload.find((supplyItem) => supplyItem.id === supply.id);

        return item ? item : supply;
      }),
    };
  },
  filterSupplyList: (state: ISupplyListState, payload: number[]) => {
    return {
      ...state,
      data: state.data.filter((supply) => !payload.includes(supply.id)),
      count: state.count - payload.length,
    };
  },
  addSupply: (state: ISupplyListState, payload: ISupply) => {
    return {
      ...state,
      data: [...state.data, payload],
    };
  },
  deleteSupply: (state: ISupplyListState, payload: number) => {
    return {
      ...state,
      data: state.data.filter((supply) => supply.id !== payload),
    };
  },
};

export const updateGroupedSupplyListState = {
  setGroupedSupplyList: (state: IGroupedSupplyListState, payload: GroupedSupplyListStateDataItemType[]) => {
    return { ...state, data: payload };
  },
  setGroupedSupplyListPart: (state: IGroupedSupplyListState, payload: GroupedSupplyListStateDataItemType[]) => {
    return { ...state, data: [...state.data, ...payload] };
  },
  setGroupedSupplyListCount: (state: IGroupedSupplyListState, payload: number) => ({ ...state, count: payload }),
  setGroupedSupplyListLoading: (state: IGroupedSupplyListState, payload: boolean) => ({ ...state, loading: payload }),
  filterGroupedSupplyList: (state: IGroupedSupplyListState, payload: number[]) => {
    return {
      ...state,
      data: state.data.map((group) => {
        return {
          ...group,
          supplies: group.supplies.filter((supply) => !payload.includes(supply.id)),
        };
      }),
    };
  },
  updateGroupedSupplyList: (state: IGroupedSupplyListState, payload: ISupply[]) => {
    return {
      ...state,
      data: state.data.map((group) => {
        return {
          ...group,
          supplies: group.supplies.map((supply) => {
            const newSupply = payload.find((payloadSupply) => payloadSupply.id === supply.id);

            return newSupply ? newSupply : supply;
          }),
        };
      }),
    };
  },
  updateDeliveryDate: (state: IGroupedSupplyListState, payload: ISupply[]) => {
    const payloadIds = payload.map((item) => item.id);
    const filteredState = state.data
      .map((group) => {
        return {
          ...group,
          supplies: group.supplies.filter((supply) => !payloadIds.includes(supply.id)),
        };
      })
      .filter((group) => !!group.supplies.length);

    const stateMap = new Map<string | null, GroupedSupplyListStateDataItemType>(
      filteredState.map((group) => [(group as IGroupedByDeliveryDateSupply).deliveryDate, group]),
    );

    payload.forEach((supply) => {
      const { deliveryDate } = supply;

      if (!stateMap.has(deliveryDate)) {
        const newGroup = { deliveryDate, supplies: [] };

        filteredState.push(newGroup);
        stateMap.set(deliveryDate, newGroup);
      }

      stateMap.get(deliveryDate)?.supplies.push(supply);
    });

    return { ...state, data: sortGroupedSupplyListByDeliveryDate(filteredState as IGroupedByDeliveryDateSupply[]) };
  },
  addSupply: (state: IGroupedSupplyListState, payload: ISupply) => {
    const groupExist = state.data.find((group) => (group as IGroupedByDeliveryDateSupply).deliveryDate === payload.deliveryDate);

    return {
      ...state,
      data: groupExist
        ? state.data.map((group) => {
            return (group as IGroupedByDeliveryDateSupply).deliveryDate === payload.deliveryDate
              ? { ...group, supplies: [...group.supplies, payload] }
              : group;
          })
        : [...state.data, { deliveryDate: payload.deliveryDate, supplies: [payload] }],
    };
  },
};

export const getSupplyStatus = (status?: string) => {
  return {
    supplyCreated: status === ESupplyStatus.Created,
    supplyPending: status === ESupplyStatus.Pending,
    supplyApproved: status === ESupplyStatus.Approved,
    supplyInProcess: status === ESupplyStatus.Processing,
    supplyInDelivery: status === ESupplyStatus.Delivery,
    supplyDelivered: status === ESupplyStatus.Delivered,
    supplyCancelled: status === ESupplyStatus.Cancelled,
  };
};

export const renderSupplyListRecords = (tab?: ESupplyListMenuTab) => {
  const incomingSupplyListForSeller = window.location.pathname === ERoute.RequestsForSeller;
  const supplyListForUser = window.location.pathname === `${ERoute.RequestsForUser}/${ERequestsNavigationTab.SupplyList}`;
  const isSupplyListProcessingTab = tab === ESupplyListMenuTab.Processing;
  const isSupplyListPendingTab = tab === ESupplyListMenuTab.Pending;
  const isSupplyListActiveTab = tab === ESupplyListMenuTab.Active;
  const isSupplyListCompletedTab = tab === ESupplyListMenuTab.Completed;

  const records = [
    {
      key: 'id',
      render: (_, { id }) => {
        return (
          <span
            className="need-list__table-item-id"
            title={`#${id}`}
            style={{
              textAlign: incomingSupplyListForSeller || isSupplyListActiveTab || isSupplyListPendingTab ? 'center' : 'left',
            }}
          >
            <span className="text-lists">#</span>

            <span className="text-accent">{id}</span>
          </span>
        );
      },
      width: 160,
    },
    {
      key: 'image',
      render: (_, { good: { image } }) => {
        return <Image size={60} src={image} />;
      },
      width: 100,
    },
    {
      key: 'name',
      render: (_, { good: { name } }) => (
        <span
          className="text-h4-item-name"
          title={name}
          style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            whiteSpace: 'normal',
          }}
        >
          {name}
        </span>
      ),
      width: 758,
    },
    {
      key: 'code',
      render: (_, { good: { sellerCode, buyerCode } }) => {
        return (
          <div>
            {sellerCode && (
              <div className="text-lists ellipsis" title={sellerCode}>
                {sellerCode}
              </div>
            )}

            {buyerCode && (
              <div className="text-tag-accent color-bright-green ellipsis" title={buyerCode}>
                {buyerCode}
              </div>
            )}
          </div>
        );
      },
      ellipsis: true,
      responsive: ['xxl'],
    },
    {
      key: 'count',
      render: (_, { count }) => <span className="text-lists">{countFormatter(count)}</span>,
      ellipsis: true,
      responsive: ['xxl'],
    },
    {
      key: 'totalPrice',
      render: (_, { count, price, priceWithTaxes }) => {
        return (
          <div>
            <div className="text-lists ellipsis" title={priceFormatter(count * price)}>
              {priceFormatter(count * price)}
            </div>

            <div className="color-dark-grey" style={{ whiteSpace: 'normal' }}>
              <span className="text-tag">сумма с НДС </span>

              <span className="text-tag ellipsis" style={{ whiteSpace: 'nowrap' }} title={priceFormatter(count * priceWithTaxes)}>
                {priceFormatter(count * priceWithTaxes)}
              </span>
            </div>
          </div>
        );
      },
    },
  ] as ColumnsType<ISupply>;

  if (supplyListForUser || isSupplyListProcessingTab || isSupplyListActiveTab || isSupplyListCompletedTab) {
    records.push({
      key: 'status',
      render: (_, { status, isCancelledBySeller }) => {
        return (
          <div className="need-list__table-item-status">
            <SupplyStatus status={status} isCancelledBySeller={isCancelledBySeller} />
          </div>
        );
      },
      width: 210,
    });
  }

  return records;
};

export const renderGroupedSupplyListRecords = (
  selectedSupplyListIds?: number[],
  onGroupSelectionChange?: (checked: boolean, groupSupplyList: ISupply[]) => void,
) => {
  const isSupplyListForManager = window.location.pathname === `${ERoute.RequestsForManager}/${ERequestsNavigationTab.SupplyList}`;
  const isProcessingSupplyListForManager =
    window.location.pathname === `${ERoute.RequestsForManager}/${ERequestsNavigationTab.SupplyList}` &&
    window.location.search.includes(ERequestsMenuTab.Processing);
  const isRequestsForSeller = window.location.pathname === ERoute.RequestsForSeller;
  const isSupplyListForSeller = window.location.pathname === `${ERoute.RequestsForSeller}/${ERequestsNavigationTab.SupplyList}`;
  const isCompletedSupplyListForSeller =
    window.location.pathname === `${ERoute.RequestsForSeller}/${ERequestsNavigationTab.SupplyList}` &&
    window.location.search.includes(ESupplyListMenuTab.Completed);

  const records = [
    {
      key: 'check',
      render: (_, { dataSource, address }) => {
        const selectedSupplies = dataSource.filter((supply: ISupply) => selectedSupplyListIds?.includes(supply.id));
        const checkAll = dataSource.length === selectedSupplies.length;
        const indeterminate = selectedSupplies.length > 0 && selectedSupplies.length < dataSource.length;

        return (
          <Checkbox
            checked={checkAll}
            indeterminate={indeterminate}
            onChange={(e: any) => onGroupSelectionChange?.(e.target.checked, dataSource)}
            disabled={!address}
          />
        );
      },
      width: 40,
      hidden: !onGroupSelectionChange,
    },
    {
      key: 'account',
      render: (_, { account }) => (
        <NameLabel icon={<AffiliateIcon className="icon-affiliate-bright-green" />} name={account.name} />
      ),
      ellipsis: true,
      responsive: ['xxl'],
    },
    {
      key: 'subdivisionName',
      render: (_, { subdivisionName }) => (
        <NameLabel icon={<AffiliateIcon className="icon-affiliate-bright-green" />} name={subdivisionName} />
      ),
      ellipsis: true,
      responsive: ['xxl'],
      hidden: (!isRequestsForSeller && !isSupplyListForSeller) || isCompletedSupplyListForSeller,
    },
    {
      key: 'user',
      render: (_, { user }) => (
        <NameLabel
          icon={<AffiliateIcon className="icon-affiliate-bright-green" />}
          name={getUserName(user.firstName, user.lastName)}
        />
      ),
      ellipsis: true,
    },
    {
      key: 'address',
      render: (_, { address }) => <span className="text-h4-item-name">{address?.name}</span>,
      width: 396,
    },
    {
      key: 'legalName',
      render: (_, { legalName }) => {
        return (
          <div className="need-list__table-item-legal" title={legalName}>
            <ContractIcon className="icon-contract-dark-grey" />

            <span className="text-lists">{legalName}</span>
          </div>
        );
      },
      ellipsis: true,
      responsive: ['xxl'],
      hidden: !isSupplyListForManager,
    },
    {
      key: 'image',
      render: (_, { dataSource }) => {
        const images = dataSource.map((supply: ISupply) => supply.good.image);
        const visibleImages = images.length > 4 ? images.slice(0, 3) : images;
        const hiddenImagesCount = images.length - visibleImages.length;

        return (
          <div className="need-list__table-item-images-container">
            {visibleImages.map((src, index) => (
              <Image key={index} size={60} src={src} />
            ))}

            {!!hiddenImagesCount && (
              <div className="text-profile0icon-text color-dark-grey need-list__table-item-image-placeholder">{`+${hiddenImagesCount}`}</div>
            )}
          </div>
        );
      },
      width: 304,
    },
    {
      key: 'totalPrice',
      render: (_, { dataSource }) => {
        const totalPrice = dataSource.reduce((acc, supply) => acc + supply.count * supply.price, 0);

        return (
          <div className="text-lists ellipsis" style={{ textAlign: 'end' }} title={priceFormatter(totalPrice)}>
            {priceFormatter(totalPrice)}
          </div>
        );
      },
      hidden: isProcessingSupplyListForManager,
    },
  ] as ColumnsType<IGroupedSupplyListItemDataSource>;

  // @ts-ignore
  return records.filter((record) => !record.hidden);
};
