import { IGoods, IGoodsListState, IGoodsState } from 'entities/Goods/Goods.models';

export const goodsListStateReducers = {
  setGoodsList: (state: IGoodsListState, payload: IGoods[]) => ({ ...state, data: payload }),
  setGoodsListCount: (state: IGoodsListState, payload: number) => ({ ...state, count: payload }),
  setGoodsListLoading: (state: IGoodsListState, payload: boolean) => ({ ...state, loading: payload }),
  setGoodsListPart: (state: IGoodsListState, payload: IGoods[]) => ({ ...state, data: [...state.data, ...payload] }),
  updateGoodsList: (state: IGoodsListState, payload: IGoods) => {
    return {
      ...state,
      data: state.data.map((goods) => (goods.id === payload.id ? payload : goods)),
    };
  },
  updateGoodsListGoodsInBasket: (state: IGoodsListState, payload: { id: number; goodInBasket: boolean }) => ({
    ...state,
    data: state.data.map((goods) => {
      if (goods.id === payload.id) {
        return { ...goods, goodInBasket: payload.goodInBasket };
      }

      return goods;
    }),
  }),
  clearGoodsListState: (state: IGoodsListState) => ({ ...state, data: [], count: 0 }),
};

export const goodsStateReducers = {
  setGoods: (state: IGoodsState, payload: IGoods | null) => ({ ...state, data: payload }),
  setGoodsLoading: (state: IGoodsState, payload: boolean) => ({ ...state, loading: payload }),
};
