/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { EScrollDirection } from 'common/const/common.enum';
import { ERoute } from 'common/const/route.enum';
import { DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootState } from 'app/store';

export interface ICatalogContext {
  page: number;
  scrollUp: boolean;
  fetchLoading: boolean;
  setPage: (value: number) => void;
  setScrollDirection: (value: EScrollDirection) => void;
  setFetchLoading: (value: boolean) => void;
  onCatalogChange: (id: number) => void;
  onCategoryChange: (id: number) => void;
}

export const CatalogContext = createContext<ICatalogContext>({
  page: DEFAULT_PAGINATION_PAGE,
  scrollUp: false,
  fetchLoading: true,
  setPage: () => {},
  setScrollDirection: () => {},
  setFetchLoading: () => {},
  onCatalogChange: () => {},
  onCategoryChange: () => {},
});

export const useCatalogContext = () => useContext(CatalogContext);

export interface IComponentProps {
  children: React.ReactNode;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

export const CatalogProviderComponent: FC<AllType> = ({ children }) => {
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [scrollDirection, setScrollDirection] = useState<EScrollDirection>(EScrollDirection.DOWN);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const { catalogId } = useParams();
  const navigate = useNavigate();

  const scrollUp = scrollDirection === EScrollDirection.UP;

  const onCatalogChange = (id: number) => {
    navigate(`${ERoute.Catalog}/${id}`);
  };

  const onCategoryChange = (id: number) => {
    if (catalogId) {
      navigate(`${ERoute.Catalog}/${catalogId}/${id}`);
    }
  };

  return (
    <CatalogContext.Provider
      value={{
        page,
        scrollUp,
        fetchLoading,
        setPage,
        setScrollDirection,
        setFetchLoading,
        onCatalogChange,
        onCategoryChange,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};

const mapState = (state: RootState) => ({
  propertyList: state.propertyListState.data,
});

export const CatalogProvider = connect(mapState)(CatalogProviderComponent);
