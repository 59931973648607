import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EInventoryActionStatus, EInventoryActionType } from 'common/const/inventory.enum';
import { ERoute } from 'common/const/route.enum';
import { showMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { useInventoryContext } from 'common/hooks/useInventoryContext';
import { DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, DEFAULT_VALUE_0, LIST_LIMIT_0, LIST_LIMIT_20 } from 'common/config';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { InventoryActionCard } from 'entities/Inventory/components/InventoryActionCard';
import { InventoryEmptyList } from 'entities/Inventory/components/InventoryEmptyList';
import { renderInventoryActionListRecords } from 'entities/Inventory/Inventory.helper';
import { IInventoryAction, IInventoryActionListPayload } from 'entities/Inventory/Inventory.models';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  menu: ReactNode;
  renderFilters: (value: IInventoryActionListPayload, setPayload: (value: IInventoryActionListPayload) => void) => ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    menu,
    renderFilters,
    // state
    inventoryActionList,
    inventoryActionListCount,
    inventoryActionListLoading,
    inventoryActionLoading,
    userInventoryStatisticsLoading,
    // dispatch
    getSubdivisionList,
    getUserList,
    setInventoryActionList,
    filterInventoryActionList,
    getInventoryActionList,
    getInventoryActionListPart,
    getInventoryActionById,
    updateInventoryAction,
    deleteInventoryAction,
    getUserInventoryStatistics,
  } = props;

  const [payload, setPayload] = useState<IInventoryActionListPayload>({
    types: [EInventoryActionType.WriteOff],
    statuses: [EInventoryActionStatus.New],
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });
  const navigate = useNavigate();
  const { page, setPage, fetchLoading, setFetchLoading, setOpenInventoryActionCard } = useInventoryContext();

  const listLoading = inventoryActionListLoading || inventoryActionLoading || userInventoryStatisticsLoading || fetchLoading;

  const onChangeRequestStatusSuccess = async (records: IInventoryAction[]) => {
    const recordIds = records.map((record) => record.id);
    const statistics = await getUserInventoryStatistics();

    if (statistics && statistics.writeOffsCount === DEFAULT_VALUE_0) {
      navigate(ERoute.InventoryList, { replace: true });
      return;
    }

    filterInventoryActionList(records);
    setOpenInventoryActionCard(false);

    const filteredInventoryActionList = inventoryActionList.filter((action) => !recordIds.includes(action.id));

    if (filteredInventoryActionList.length === LIST_LIMIT_0) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      setPayload(newPayload);
      getInventoryActionList(newPayload);
    }
  };

  const onAcceptRequestClick = (record: IInventoryAction) => {
    updateInventoryAction({
      id: record.id,
      onSuccess: () => {
        showSuccessMessage('Запрос о списании оборудования принят');
        onChangeRequestStatusSuccess([record]);
      },
    });
  };

  const onCancelRequestClick = (records: IInventoryAction[]) => {
    const recordIds = records.map((record) => record.id);

    deleteInventoryAction({
      ids: recordIds,
      onSuccess: async () => {
        showMessage({
          content: 'Запрос о списании оборудования отклонен.',
          icon: <BinIcon className="icon-bin-dark-grey" />,
        });
        onChangeRequestStatusSuccess(records);
      },
    });
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setInventoryActionList([]);

      const statistics = await getUserInventoryStatistics();

      if (statistics && statistics.writeOffsCount === DEFAULT_VALUE_0) {
        navigate(ERoute.InventoryList, { replace: true });
        return;
      }

      await getSubdivisionList({ limit: LIST_LIMIT_0 });
      await getUserList({ limit: LIST_LIMIT_0 });
      await getInventoryActionList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!inventoryActionListLoading && inventoryActionList.length < inventoryActionListCount}
        scrollToTopTrigger={[payload]}
        onLoad={() => {
          setPage(page + 1);
          getInventoryActionListPart({ ...payload, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}
        {navigation}
        {menu}
        {renderFilters(payload, setPayload)}

        <Table
          className={`
                table-hover inventory-list__table
                ${
                  !!inventoryActionList.length &&
                  inventoryActionList.length === inventoryActionListCount &&
                  'inventory-list__table-end-of-list'
                }
                ${!inventoryActionList.length && 'inventory-list__table-empty'}
              `}
          dataSource={inventoryActionList.map((inventory) => ({ ...inventory, key: inventory.id }))}
          columns={renderInventoryActionListRecords({ isIncoming: true, onAcceptRequestClick, onCancelRequestClick })}
          showHeader={false}
          pagination={false}
          onRow={({ id }) => ({
            onClick: async () => {
              await getInventoryActionById(id);
              setOpenInventoryActionCard(true);
            },
          })}
          locale={{
            emptyText: <InventoryEmptyList open={!listLoading && !inventoryActionList.length} />,
          }}
          loading={{ spinning: listLoading, indicator: <SpinIndicator /> }}
        />
      </InfiniteScrollContainer>

      <InventoryActionCard isIncoming onAccept={onAcceptRequestClick} onCancel={onCancelRequestClick} />

      <GoodsCard />
    </>
  );
};

const mapState = (state: RootState) => ({
  inventoryActionList: state.inventoryActionListState.data,
  inventoryActionListCount: state.inventoryActionListState.count,
  inventoryActionListLoading: state.inventoryActionListState.loading,
  inventoryActionLoading: state.inventoryActionState.loading,
  userInventoryStatisticsLoading: state.userInventoryStatisticsState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSubdivisionList: dispatch.subdivisionListState.getSubdivisionList,
  getUserList: dispatch.userListState.getUserList,
  setInventoryActionList: dispatch.inventoryActionListState.setInventoryActionList,
  filterInventoryActionList: dispatch.inventoryActionListState.filterList,
  getInventoryActionList: dispatch.inventoryActionListState.getInventoryActionList,
  getInventoryActionListPart: dispatch.inventoryActionListState.getInventoryActionListPart,
  getInventoryActionById: dispatch.inventoryActionState.getInventoryActionById,
  updateInventoryAction: dispatch.inventoryActionState.updateInventoryAction,
  deleteInventoryAction: dispatch.inventoryActionState.deleteInventoryAction,
  getUserInventoryStatistics: dispatch.userInventoryStatisticsState.getUserInventoryStatistics,
});

export const WriteOffIncomingInventoryActionList = connect(mapState, mapDispatch)(Component);
